.rd1 {
	border-radius: 1px;
}

.rd2 {
	border-radius: 2px;
}

.rd3 {
	border-radius: 3px;
}

.rd4 {
	border-radius: 4px;
}

.rd5 {
	border-radius: 5px;
}

.verticalCenter {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.vertCenterForm {
	width: 100%;

	p {
		margin: 0 0 15px;

		label {
			display: block;
			position: relative;

			span {
				position: absolute;
				margin-bottom: .5em;
				font-size: .75em;
				line-height: 45px;
				padding: 0 1.25em;
				color: #777;
				transform-origin: left;
				transition: transform ease-out .1s, -webkit-transform ease-out .1s;
				user-select: none;
				pointer-events: none;
			}
			
			&.withValue {

				span {
					transform: scale(.75) translateY(-16px);
				}

				.joinInput {
					padding: .5em 15px 0;
				}
			}

			&.error span {
				color: #de2222;
			}
		}
	}
}

.vertCenterErrorMsgs {
	height: 110px;
	color: #de2222;

	.msg {
		padding: .25em;
		font-size: .9em;
		text-align: center;
	}
}
