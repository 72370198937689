/*colors*/
$blueMain: #0093cc; /*#0083b5;*/
$nearlyWhite:	#fbfbfb;
$black1: #111;
$black2: #222;
$black3: #333;
$black4: #444;
$black5: #555;
$black6: #666;
$black7: #777;
$black8: #888;

$redMain: #ff5c5c;

$grayLte: #eaeaea;

$inputWhite: #fdfdfd;

$blockBorderColor: #dbdbdb;
$blockBorder: 1px solid $blockBorderColor;
$inputBorderColor: #d5d5d5;
$inputBorder: 1px solid $inputBorderColor;

$defaultBoxShadow: 0 3px 7px 0px rgb(0 0 0 / 10%);
$inputBoxShadow: 0 0 4px 2px rgb(0 0 0 / 3%);

/* font */
$fontSizeBase: 1rem;

$headingMarginBottom: 1rem;
$h1FontSize: $fontSizeBase * 2.5;
$h2FontSize: $fontSizeBase * 2;
$h3FontSize: $fontSizeBase * 1.75;
$h4FontSize: $fontSizeBase * 1.5;
$h5FontSize: $fontSizeBase * 1.25;
$h6FontSize: $fontSizeBase;
