@import '@ui/styles/variables.scss';

.publicationCell {
}

.uploadSection {
	display: flex;
	padding: 10px;
	border-radius: 4px;
	font-size: 14px;
	background-color: #f7f7f7;
}

.logos {
	display: flex;
}

.logoRow {
	display: flex;
	margin-bottom: 10px;
}

.logoCont {
	width: 100px;
	height: 100px;
	border: 3px solid transparent;
	box-sizing: content-box;
}

.highlighted {
	border: 3px solid $blueMain;
}

.highlightedEmpty {
	border: 3px dashed $blueMain;
	color: $blueMain;
}

.unhighlightedEmpty {
	border: 1px dashed #aaa;
	color: #999;
}

.logo {
	display: flex;
	margin-right: 5px;
	border-radius: 4px;
	overflow: hidden;
	cursor: pointer;
}

.logoPlaceholder {
	flex: 1;
	width: 100px;
	height: 100px;
	border-radius: 3px;
	font-size: 14px;
	text-align: center;
	line-height: 100px;
}

.logoUrl {
	composes: std from '@ui/styles/forms.scss';
	composes: sm from '@ui/styles/forms.scss';
	composes: rd2 from '@ui/styles/common.scss';
	background-color: $inputWhite;
	margin-right: 5px;
}

.btnSubmitUrl {
	composes: sm from '@ui/styles/buttons.scss';
	composes: rd2 from '@ui/styles/common.scss';
	background-color: $blueMain;
	color: white;
}

.hidden {
	visibility: hidden;
}

.uploadCont {
	flex: 1;
	margin-left: 10px;
}
