.statusBar {
	display: flex;
	justify-content: space-between;
	gap: 1em;
	padding: .5em 0;
	font-size: .95em;
}

.formLabel {
	display: flex;
	cursor: default;
}

.pageSize {
	display: flex;
	align-items: center;
	gap: .5em;
}

.info {
	display: flex;
	gap: .5em;
}

.showing {
	display: flex;
	align-items: center;
}

.pagination {
	display: flex;
	align-items: center;
	gap: .5em;

	.prevBtn, .nextBtn {
		font-size: .9em;
	}

	.btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 2em;
		height: 2em;
		border-radius: 4px;
		cursor: pointer;
        user-select: none;

		&.active {
			background-color: #eee;
			cursor: default;
		}
	}
}
