@import '../../../styles/variables.scss';

.root {
	display: flex;
	flex: 1;
	justify-content: space-between;
    font-size: 14px;

	&.mobile {
		box-shadow: none;
	}
}

.segment {
	display: flex;
    flex: 1;
}

.left {
	display: flex;
	align-items: center;
}

.center {
	display: flex;
	justify-content: center;
	max-width: 45em;
	flex: 1;
}

.right {
	display: flex;
	width: 3em;
}
