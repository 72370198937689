.root {
	display: flex;
	flex: 1;
	flex-direction: column;
	height: 100%;
	min-width: 0;
}

.mainCont {
	display: flex;
	flex: 1;
	overflow: auto;
}
