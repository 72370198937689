.root {
	display: flex;
	flex: 1;
	overflow: auto;

	&.mobile {
		display: block;
		padding: 0;
	}
}
