.header {
	display: flex;
	flex-direction: column;
	height: 3.5em;
	background-color: white;
	z-index: 100;

	&.mobile {
		z-index: 0;
	}
}
