@import '../../styles/variables';

.root {
	display: flex;
	height: 100%;
	flex-direction: column;
	/*flex: 1;*/
	align-items: center;
	justify-content: center;
}

.mobile {
	.loginForm {
		width: 100%;
	}
}

.msg {
	font-size: 13px;
	text-align: center;
	color: white;
	background-color: #de2222;
	padding: 10px;
	border-radius: 4px;
}

.logoCont {
	position: relative;
	text-align: center;
}

.logo {
	display: block;
	margin: auto auto 15px;
	width: 200px;
}

.title {
	margin-bottom: 30px;
	font-family: 'Lordish', 'Open Sans';/*'IM Fell English SC', serif;*/
	font-size: 50px;
	font-weight: normal;
	text-align: center;
	color: #333;
}

.loginForm {
	width: 350px;
}

.textInput {
	composes: std from '../../styles/forms.scss';
	composes: md from '../../styles/forms.scss';
	composes: rd4 from '../../styles/common.scss';
	background-color: $nearlyWhite;
}

.loginBtn {
	composes: rd4 from '../../styles/common.scss';
	composes: lg from '../../styles/buttons.scss';
	width: 100%;
	background-color: #111;
	font-family: 'Open Sans';
	font-size: 1.25em;
	color: white;
}

.fieldRow {
	margin: 0 0 15px;
}

.fieldLabel {
	display: block;
	position: relative;

	span {
		position: absolute;
		margin-bottom: .5em;
		font-size: .75em;
		line-height: 45px;
		padding: 0 1.25em;
		color: #777;
		transform-origin: left;
		transition: transform ease-out .1s, -webkit-transform ease-out .1s;
		user-select: none;
		pointer-events: none;
	}
	
	&.withValue {

		span {
			transform: scale(.75) translateY(-16px);
		}

		.joinInput {
			padding: .5em 15px 0;
		}
	}

	&.error span {
		color: #de2222;
	}
}

.links {
	font-size: .9em;
	text-align: center;
	color: $black2;
}
