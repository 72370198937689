.root {
    display: flex;
    flex-direction: column;
    width: 400px;
    padding: 1em;
    border-radius: 4px;
    font-family: monospace;
    background-color: #4b91cd;
    color: white;
}
