@import '@ui/styles/variables.scss';

.inner {
    display: flex;
    align-items: center;
    flex: 1;
    border-bottom: 1px solid #dbdbdb;
    box-shadow: 0 3px 7px 0px rgb(0 0 0 / 6%);
    background-color: #fbfbfb;
}

.btnRow {
	display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    gap: .25em;
}

.btn {
	composes: sm from '@ui/styles/buttons.scss';
	composes: rd3 from '@ui/styles/common.scss';
	background-color: $grayLte;
    border: 1px solid #d1d1d1;
	color: black;
}
