.root {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.link {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1em;
    text-decoration: none;
	color: inherit;
}
