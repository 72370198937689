@import '../../styles/variables.scss';

.title {
	text-align: center;
	font-size: 13px;
	padding: 10px;
	color: $black6;
	font-weight: bold;
}

.cell {
	composes: rd2 from '../../styles/common.scss';
	margin: 10px;
	border: 1px solid #eee;
	background-color: white;
	font-family: 'Open Sans';
}

.flexGrow1 {
	flex-grow: 1;
}

.flexGrow2 {
	flex-grow: 2;
}

.flexGrow3 {
	flex-grow: 3;
}

.topInputRow {
	display: flex;
	input {
		flex: 1;
		background-color: $inputWhite;
	}
}

.btnRow {
	display: flex;
	flex-direction: row-reverse;
	height: 40px;
}

.btnLive {
	composes: sm from '../../styles/buttons.scss';
	composes: rd2 from '../../styles/common.scss';
	background-color: $grayLte;
	color: black;

	.live {
		color: $blueMain;
	}
}

.btnSave {
	composes: sm from '../../styles/buttons.scss';
	composes: rd2 from '../../styles/common.scss';
	margin-left: 5px;
	background-color: $blueMain;
	color: white;
}

.btnSubmit {
	composes: sm from '../../styles/buttons.scss';
	composes: rd2 from '../../styles/common.scss';
	margin-left: 5px;
	background-color: $blueMain;
	color: white;
}

.btnView {
	composes: xxs from '../../styles/buttons.scss';
	composes: rd2 from '../../styles/common.scss';
	margin-left: 5px;
	background-color: $grayLte;
	color: $black2;
}

.btnDelete {
	composes: xxs from '../../styles/buttons.scss';
	composes: rd2 from '../../styles/common.scss';
	margin-left: 5px;
	background-color: $redMain;
	color: white;
}

.cellContent {
	padding: 0 20px;
}

.fieldRow {
	margin-bottom: 16px;
}

.fieldLabel {
	display: block;
	margin-bottom: 5px;
	font-size: 12px;
	font-weight: bold;
	color: $black6;
}

.fieldInput {
	composes: std from '../../styles/forms.scss';
	composes: sm from '../../styles/forms.scss';
	composes: rd2 from '../../styles/common.scss';
	background-color: $inputWhite;
}

.fieldInlineRow {
    margin-bottom: 16px;
}

.fieldInlineLabel {
    margin-bottom: 16px;
    font-size: 12px;
    font-weight: bold;
    color: $black6;
}

.fieldTextarea {
	composes: std from '../../styles/forms.scss';
	composes: rd2 from '../../styles/common.scss';
	height: 60px;
	padding: 6px 8px;
	font-family: 'Open Sans';
	font-size: 12px;
	background-color: $inputWhite;
}

.list {
	flex: 1;
	padding: 10px 0;
	font-size: 14px;
	overflow: auto;
}

.listItem {
	display: flex;
	padding: 2px;
}

.listItemContent {
	flex: 1;
	line-height: 20px;
	color: $black2;
}
