.root {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    padding: 2em;
}

.feedList {
    td {
        padding: .25em 1em;
    }
}
