.table {
    width: 100%;
    position: relative;
	border-collapse: separate;
    font-family: 'Open Sans';
    font-size: 14px;

    th {
        font-weight: bold;
        color: #444;
        user-select: none;

        /* For possible bug */
        input {
            width: 100%;
        }
    }
    td {
        padding: .25em .5em;
    }
    
}

.head {
	th {
		position: sticky;
		top: 0;
		padding: .5em 0;
		color: #444;
		background-color: white;
		border-bottom: 1px solid #ddd;
		box-shadow: 0 1px 5px -2px rgba(0, 0, 0, 0.1);
	}

	.colLabel {
		margin-right: .75em;
		cursor: pointer;
		user-select: none;
        white-space: nowrap;
	}

	.sortIcon {
		display: inline-block;
        margin-left: .5em;
		font-size: .9em;
	}
}

.publicationTrCol {
    display: flex;
    align-items: center;
    gap: .25em;
}

.liveCol, .enabledCol, .aggregateCol, .fetchErrorsCol, .lastErrorCol {
    text-align: center;
}

.colFilterCont {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: .5em 0;
}

.formCont {
	display: flex;
	flex-direction: column;
	/*width: calc(100% - 2em);*/
	gap: .5em;
	flex: 1;
	padding: .4em;

	input, select {
		padding: .25em 0;
		border: 1px solid #aaa;
		border-radius: 2px;
		background-color: white;

		&[multiple] {
			option {
				padding-left: .5em;
			}
		}
	}

	input, select:not([multiple]) {
		padding: .25em .5em;
	}
}

.clear {
	color: #aaa;
	font-size: .8em;
	font-weight: normal;
	cursor: pointer;
}

.imgCont {
    display: flex;
    align-items: center;

    img {
        width: 20px;
        border-radius: 2px;
    }
}

.colLabelCont {
    cursor: pointer;

    label {
        cursor: pointer;
    }
}

.colLabel {
    margin-right: .75em;
    user-select: none;
}

.sortIcon {
    display: inline-block;
    font-size: .9em;
}

.editable:hover {

    cursor: pointer;

    .edit {
        visibility: visible;
    }
}
    
.edit {
    margin-left: .25em;
    visibility: hidden;
    color: #555;
}

.binMenu {
    padding: .25em;
    border-radius: 3px;
}
