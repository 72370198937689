.root {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.header {
    display: flex;
    justify-content: space-between;
    margin: 1em 0;
    padding: 0 1em;
    font-size: .9em;
}

.tableCont {
    display: flex;
    flex: 1;
    overflow: auto;
}

.filterBtnCont {
	padding: .5em;
	color: #555;
	cursor: pointer;
}

.left {
	display: flex;
	flex: 1;
	align-items: center;
	gap: 1em;
}

.center {
	display: flex;
	flex: 1;
	justify-content: center;
}

.right {
	display: flex;
	flex-direction: column;
	flex: 1;
}
