.valueContOuter {
	display: flex;
	width: 100%;
}

.valueCont {
	display: flex;
	align-items: center;
	width: 100%;
	height: 2.5em;
	padding: 0 .75em;
	box-sizing: border-box;
	border: 1px solid #ccc;
	border-radius: 4px;
	font-size: .9em;
	color: #333;
	background-color: #fafafa;
	cursor: default;
	user-select: none;
	white-space: nowrap;
}

.menu {
	display: flex;
	flex-direction: column;
	padding: .5em 0;
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 4px;
	font-size: .9em;
	box-shadow: 0 0 4px 2px rgb(0 0 0 / 5%);
    z-index: 10;
}

.menuItem {
	display: flex;
	padding: .5em 1em;
	cursor: default;
	user-select: none;
	cursor: pointer;

	&:hover {
		background-color: #eee;
	}
}

.noOptions {
	text-align: center;
	color: #999;
	cursor: default;
	user-select: none;
}

.arrow {
	position: absolute;
	background-color: white;
	border-left: 1px solid #d5d5d5;
	border-top: 1px solid #d5d5d5;
	width: 10px;
	height: 10px;
	transform: rotate(45deg);
}
