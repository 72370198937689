@import './variables';
@import './reset';

html, body {
    height: 100%;
    font-family: 'Open Sans';
}

@font-face {
	font-family: 'Lordish';
	src: url('@nl/assets/fonts/Lordish.ttf') format('truetype');
}

@font-face {
	font-family: 'Chunk';
	src: url('@nl/assets/fonts/Chunk.otf') format('opentype');
}

@font-face {
	font-family: 'Chromeye';
	src: url('@nl/assets/fonts/chromeye.ttf') format('truetype');
}

#root {
    display: contents;
}
