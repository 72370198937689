.columnItem {
	display: flex;
	align-items: center;
	padding: .2em 1em;
	cursor: pointer;
}

.columnItemInput {
	width: initial;
}

.columnItemLabel {
	display: flex;
	margin-left: .25em;
	white-space: nowrap;
}
