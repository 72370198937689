@import './variables.scss';

button {
	border: none;
	outline: none;
	cursor: pointer;
	padding: 0;
}

.xxs {
	height: 20px;
	padding: 2px 8px;
	font-size: 11px;
}

.xs {
	height: 25px;
	padding: 2px 8px;
	font-size: 12px;
}

.sm {
	height: 30px;
	padding: 3px 10px;
}

.md {
	height: 44px;
	padding: 4px 15px;
}

.lg {
	height: 50px;
	padding: 5px 20px;
}

.mobile {
	width: 100%;
}
